import React, { useState } from 'react'
import Layout from '../../components/Layout'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

const ServicesPage = () => {
    const [houseIsActive, setHouseIsActive] = useState(false);
    const [businessIsActive, setBusinessIsActive] = useState(false);
    const [corporationIsActive, setCorporationIsActive] = useState(false);
    const [governmentIsActive, setGovernmentIsActive] = useState(false);

    const toggleActiveMenu = (selectedOption) => {
        switch(selectedOption){
            case "House":
                setHouseIsActive(true);
                setBusinessIsActive(false);
                setCorporationIsActive(false);
                setGovernmentIsActive(false);
                break;
            case "Business":
                setHouseIsActive(false);
                setBusinessIsActive(true);
                setCorporationIsActive(false);
                setGovernmentIsActive(false);
                break;
            case "Corporation":
                setHouseIsActive(false);
                setBusinessIsActive(false);
                setCorporationIsActive(true);
                setGovernmentIsActive(false);
                break;
            case "Government":
                setHouseIsActive(false);
                setBusinessIsActive(false);
                setCorporationIsActive(false);
                setGovernmentIsActive(true);
                break;
            default:
                break;
        }       
    };
    return (<Layout>
        <section className="section">
        <div className="container">
            <div className="content">
                <p>En ReciclApp tenemos la solución adecuada a tus necesidades para ti que estás en casa, negocio, escuela o gobierno:</p>
            </div>
            <div className="content">
                <Row xs={1} md={4}>
                    <Col>
                        <Card>
                            <Card.Img variant="top" src="/img/service/house.png" />
                            <Card.Body>
                                <Card.Title>Casa Habitación</Card.Title>
                                <Card.Text className={houseIsActive ? "card-description-active" : "card-description"}>
                                    <ul>
                                        <li>Recolección gratuita de reciclables</li>
                                        <li>Marketplace para compra de descuentos</li>
                                        <li>Recolección premium de orgánicos</li>
                                        <li>Membresía/Suscripción Reciclapp</li>
                                    </ul>
                                </Card.Text>
                                <Card.Footer>
                                    <div className="d-grid gap-2">
                                        <Button variant="success" onClick={() => toggleActiveMenu("House")}>Ver Todo</Button>
                                    </div> 
                                </Card.Footer>        
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Img variant="top" src="/img/service/small-business.png" />
                            <Card.Body>
                                <Card.Title>Escuelas y Negocios</Card.Title>
                                <Card.Text className={businessIsActive ? "card-description-active" : "card-description"}>
                                    <ul>
                                        <li>Recolección gratuita de reciclables</li>
                                        <li>Marketplace para venta de descuentos</li>
                                        <li>Recolección premium de orgánicos</li>
                                        <li>Membresía/Suscripción Reciclapp</li>
                                        <li>Promoción como entidad/negocio medioambientalmente responsable</li>
                                        <li>Talleres de concientización de reciclaje y upcycling</li>
                                    </ul>
                                </Card.Text>
                                <Card.Footer>
                                    <div className="d-grid gap-2">
                                        <Button variant="success" onClick={() => toggleActiveMenu("Business")}>Ver Todo</Button>
                                    </div> 
                                </Card.Footer>   
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Img variant="top" src="/img/service/corporation.png" />
                            <Card.Body>
                                <Card.Title>Grandes Empresas</Card.Title>
                                <Card.Text className={corporationIsActive ? "card-description-active" : "card-description"}>
                                    <ul>
                                        <li>Recolección gratuita de reciclables</li>
                                        <li>Marketplace para venta de descuentos</li>
                                        <li>Recolección premium de orgánicos</li>
                                        <li>Membresía/Suscripción Reciclapp</li>
                                        <li>Promoción como entidad/negocio medioambientalmente responsable</li>
                                        <li>Talleres de concientización de reciclaje y upcycling</li>
                                        <li>Consultoria energética, robótica y automatización</li>
                                        <li>Capacitaciones para personal ISO 9000</li>
                                    </ul>
                                </Card.Text>
                                <Card.Footer>
                                    <div className="d-grid gap-2">
                                        <Button variant="success" onClick={() => toggleActiveMenu("Corporation")}>Ver Todo</Button>
                                    </div> 
                                </Card.Footer>   
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Img variant="top" src="/img/service/government.png" />
                            <Card.Body>
                                <Card.Title>Gobierno</Card.Title>
                                <Card.Text className={governmentIsActive ? "card-description-active" : "card-description"}>
                                    <ul>
                                        <li>Recolección gratuita de reciclables</li>
                                        <li>Recolección premium de orgánicos</li>
                                        <li>Membresía/Suscripción Reciclapp</li>
                                        <li>Promoción como entidad/negocio medioambientalmente responsable</li>
                                        <li>Talleres de concientización de reciclaje y upcycling</li>
                                    </ul>
                                </Card.Text>
                                <Card.Footer>
                                    <div className="d-grid gap-2">
                                        <Button variant="success" onClick={() => toggleActiveMenu("Government")}>Ver Todo</Button>
                                    </div> 
                                </Card.Footer>   
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
        </section>
    </Layout>

    )
}

export default ServicesPage